// Banner

import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import cls from 'classnames';
import { MyContext } from '@/config/context-manager';

import styles from './style.module.scss';

const Banner = () => {
  const { t } = useTranslation('notFound');
  const { locale } = useContext(MyContext);
  const [countdown, setCountdown] = useState(3);

  const router = useRouter();

  useEffect(() => {
    const redirect = setTimeout(() => {
      router.push('/');
    }, 3000);
    return () => clearTimeout(redirect);
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }

    return undefined;
  }, []);

  return (
    <div
      className={cls(
        styles.container,
        {
          [styles.localeEn]: locale === 'en',
        },
        'pt-[110px]',
      )}
    >
      <div className={styles.banner}>
        <div className={styles.imgBox}>
          <Image src="/images/notFound/monkey.jpg" width={590} height={315} priority alt="" />
        </div>
        <div className={styles.infoBox}>
          <h1 className={styles.title}>{t('标题')}</h1>
          <p className={styles.info}>{t('说明')}</p>
          <p className={styles.link} dangerouslySetInnerHTML={{ __html: t('跳转', { 时间: countdown || '0' }) }} />
        </div>
      </div>
    </div>
  );
};

export default Banner;
